@import '../../style/guideline.scss';

.svg-image {
  position: relative;

  display: flex;

  &_clickable {
    cursor: pointer;
  }

  svg {
    width: 100%;

    transition: $transition;
  }
}
