@import '../../../node_modules/rc-slider/assets/index.css';
@import '../../style/guideline';

$handleShadow: 0 0 4px rgba(0,0,0,0.6);

.rc-slider {
  height: 20px;
}

.rc-slider-step,
.rc-slider-track,
.rc-slider-rail {
  height: 10px;
}

.rc-slider-handle {
  width: 24px;
  height: 24px;
  margin-top: -7px;
}

.rc-slider-track {
  background-color: $blue;
}

.rc-slider-handle {
  border-color: $blue;
  box-shadow: $handleShadow;

  &:active,
  &:focus,
  &:hover {
    cursor: pointer;

    border-color: darken($blue, 0.25);
    box-shadow: $handleShadow;

  }
}
