.modal[data-modal='rebuild'] {
  .modal-content {
    display: flex;
    flex-direction: column;

    width: 768px;
    max-width: 100%;
    background: white;
    padding: 40px 40px;
    border-radius: 14px;
  }

  .modal-title {
    margin-bottom: 24px;
  }

  .modal-file {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + .modal-file {
      margin-top: 16px;
    }
    .button + .button {
      margin-left: 16px;
    }
  }

  .modal-buttons {
    display: flex;
    flex-direction: column;

    margin-top: 24px;

    .button + .button {
      margin-top: 16px;
    }
  }
}