.page[data-page='error'] {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  img {
    width: 80%;
    max-width: 420px;
  }

  .description {
    margin-top: 16px;
  }

  .status {
    font-size: 100px;
  }
  
  .button {
    margin-top: 32px;
  }
}
