$media-direction: max;

$breakpoints: (
  web: 1920,
  tablet: 1024,
  mobile: 768
);

@mixin css_property($property, $value) {
  @if type_of($value) == string {
    #{$property}: unquote($value);
  } @else {
    #{$property}: $value;
  }
}

@function splitScreens($string, $seperator) {
  $string: inspect(quote($string));
  $sepId: str-index(quote($string), $seperator);
  @if ($sepId) {
    $array: (
      quote(str-slice($string, 0, $sepId - 1)),
      quote(str-slice($string, $sepId + 1))
    );
    @return $array;
  } @else {
    @return $string;
  }
}

@mixin media($properties, $orientation: false, $media-direction: max) {
  @each $property, $value in $properties {
    @if type_of($value) == 'map' {
      @each $screen, $val in $value {
        $screen: splitScreens($screen, '-');
        @if type_of($screen) == 'list' {
          $min_screen: nth($screen, 1);
          $max_screen: nth($screen, 2);
          @if $min_screen == min or $min_screen == max {
            $media-direction: $min_screen;
            @if $orientation {
              @if map-has-key($breakpoints, $max_screen) {
                @media only screen and (#{$media-direction}-width: map-get($breakpoints, $max_screen) + 'px') and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              } @else {
                @media only screen and (#{$media-direction}-width: $max_screen + 'px')  and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              }
            } @else {
              @if map-has-key($breakpoints, $max_screen) {
                @media only screen and (#{$media-direction}-width: map-get($breakpoints, $max_screen) + 'px') {
                  @include css_property($property, $val);
                }
              } @else {
                @media only screen and (#{$media-direction}-width: $max_screen + 'px') {
                  @include css_property($property, $val);
                }
              }
            }
          } @else {
            @if $orientation {
              @if map-has-key($breakpoints, $min_screen) and
                map-has-key($breakpoints, $max_screen)
              {
                @media only screen and (min-width: map-get($breakpoints, $min_screen) + 'px') and (max-width: map-get($breakpoints, $max_screen) + 'px') and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              } @else if
                map-has-key($breakpoints, $min_screen) and not
                map-has-key($breakpoints, $max_screen)
              {
                @media only screen and (min-width: map-get($breakpoints, $min_screen) + 'px') and (max-width: $max_screen + 'px') and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              } @else if not
                map-has-key($breakpoints, $min_screen) and
                map-has-key($breakpoints, $max_screen)
              {
                @media only screen and (min-width: $min_screen + 'px') and (max-width: map-get($breakpoints, $max_screen) + 'px') and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              } @else {
                @media only screen and (min-width: $min_screen + 'px') and (max-width: $max_screen + 'px') and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              }
            } @else {
              @if map-has-key($breakpoints, $min_screen) and
                map-has-key($breakpoints, $max_screen)
              {
                @media only screen and (min-width: map-get($breakpoints, $min_screen) + 'px') and (max-width: map-get($breakpoints, $max_screen) + 'px') {
                  @include css_property($property, $val);
                }
              } @else if
                map-has-key($breakpoints, $min_screen) and not
                map-has-key($breakpoints, $max_screen)
              {
                @media only screen and (min-width: map-get($breakpoints, $min_screen) + 'px') and (max-width: $max_screen + 'px') {
                  @include css_property($property, $val);
                }
              } @else if not
                map-has-key($breakpoints, $min_screen) and
                map-has-key($breakpoints, $max_screen)
              {
                @media only screen and (min-width: $min_screen + 'px') and (max-width: map-get($breakpoints, $max_screen) + 'px') {
                  @include css_property($property, $val);
                }
              } @else {
                @media only screen and (min-width: $min_screen + 'px') and (max-width: $max_screen + 'px') {
                  @include css_property($property, $val);
                }
              }
            }
          }
        } @else {
          @if $screen == 'print' {
            @media print {
              @include css_property($property, $val);
            }
          } @else {
            @if $orientation {
              @if map-has-key($breakpoints, $screen) {
                @media only screen and (#{$media-direction}-width: map-get($breakpoints, $screen) + 'px') and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              } @else {
                @if $screen == all {
                  @media only screen and (orientation: $orientation) {
                    @include css_property($property, $val);
                  }
                } @else {
                  @media only screen and (#{$media-direction}-width: $screen + 'px') and (orientation: $orientation) {
                    @include css_property($property, $val);
                  }
                }
              }
            } @else {
              @if map-has-key($breakpoints, $screen) {
                @media only screen and (#{$media-direction}-width: map-get($breakpoints, $screen) + 'px') {
                  @include css_property($property, $val);
                }
              } @else {
                @if $screen == all {
                  @include css_property($property, $val);
                } @else {
                  @media only screen and (#{$media-direction}-width: $screen + 'px') {
                    @include css_property($property, $val);
                  }
                }
              }
            }
          }
        }
      }
    } @else {
      @if $property == 'extend' {
        @extend #{unquote($value)} !optional;
      } @else {
        @include css_property($property, $value);
      }
    }
  }
}
