.shifter {
  position: relative;
}

.shifter-content {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;

  opacity: 0.5;
  /* background: red; */

}