@import '../../style/guideline.scss';

.choice {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100vw;
  height: 100%;

  .title {
    margin-bottom: 40px;
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    max-width: 1120px;
    margin-top: -30px;
    margin-right: -30px;
    padding: 0 20px;

    & > div {
      display: flex;
      align-items: center;
      flex-basis: 220px;
      flex-direction: column;
      justify-content: center;

      box-sizing: border-box;
      margin-top: 16px;
      margin-right: 40px;
      padding: 34px;

      cursor: pointer;
      transition: $transition;
      text-align: center;

      line-height: 1.25;

      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }

      &> .figure {
        font-size: 80px !important;
        line-height: 1.2 !important;
      }
    }
  }
}
