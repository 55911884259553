@import '../../style/guideline.scss';

.ExportFormAttachments {
  .FormGroup-content {
    flex-direction: column;
  }

  .button {
    width: 100%;
  }

  &-files {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;

    & + .ExportFormAttachments-files {
    }

    > div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: white;

      & + div {
        margin-top: 10px;
      }
    }
  }

  &-deleteFile {
    cursor: pointer;
    transition: $transition;

    &:hover {
      opacity: 0.5;
    }
  }
}
