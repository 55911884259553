.ExportForm {
  &-canvasOffset {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &-autoGrid {
    margin-bottom: 20px;
  }
}
