@import '../../style/guideline.scss';

.Input {
  width: 100%;

  outline: none;

  font-size: 14px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &-withButtonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .button {
      margin-left: 20px;
    }
  }

  &-postfix {
    margin: 0 8px;
    color: white;
  }

  &:not([type='file']) {
    box-sizing: border-box;
    height: 50px;
    margin: 0;
    padding: 0 15px;
    border: 1px solid #000;
    border-radius: $borderRadius;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
};
