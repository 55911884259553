@import '../../style/guideline.scss';

$blueShadow: rgba(25, 87, 234, 0.35);

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 17px 24px;

  cursor: pointer;
  transition: $transition;
  transition-property: color, background-color, box-shadow;

  color: $white;
  border: none;
  border-radius: $borderRadius;
  outline: none !important;
  background: $blue;
  box-shadow: 0px 14px 16px -10px $blueShadow;
  // font-weight: 500;

  font-family: 'Roboto', sans-serif;
  font-size: 16px;

  &:hover {
    background: lighten($blue, 10%);
    box-shadow: 0px 10px 16px -10px $blueShadow;
  }

  &:focus {
    outline: none;
  }

  &_full-width {
    width: 100%;
  }

  &_white {
    color: $blackBlue;
    background: white;
    box-shadow: none;

    &:hover {
      background: darken($white, 10%);
      box-shadow: none;
    }
  }

  &_second {
    color: $white;
    background: $blackBlue;
    box-shadow: none;

    &:hover {
      background: darken($blackBlue, 10%);
      box-shadow: none;
    }
  }

  &_red {
    color: $white;
    background: $red;
    box-shadow: none;

    &:hover {
      background: darken($red, 10%);
      box-shadow: none;
    }
  }
}
