@import './media.scss';

// colors

$white: #fff;
$black: #000;
$red: #d33232;
$blue: #2e66bf;
$blackBlue: #0d264e;

$firstColor: #3e3e4c;
$secondColor: #eefcff;

$borderRadius: 4px;

// effects

$transition: all 250ms;

$zIndexModal: 10000;