@import '../../style/guideline.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.typography {
  font-family: 'Roboto', sans-serif;
  
  &_clickable {
    cursor: pointer;
  }

  &_uppercase {
    text-transform: uppercase;
  }

  &_button-type {
    margin: 0;
    padding: 0;

    border: none;
    outline: none;
  }

  &_mark {
    &_bold {
      font-weight: 700;
    }

    &_medium {
      font-weight: 500;
    }

    &_regular {
      font-weight: 400;
    }
  }

  &_color {
    &_white {
      color: $white;
    }

    &_black {
      color: $black;
    }

    &_blue {
      color: $blue;
    }

    &_black-blue {
      color: $blackBlue;
    }
  }

  &.typography_type_title {
    &.typography_size {
      &_min {
        font-size: 30px;
      }

      &_middle {
        font-size: 40px;
      }

      &_max {
        font-size: 50px;
      }
    }
  }

  &.typography_type_subtitle {
    &.typography_size {
      &_min {
        font-size: 16px;
      }

      &_middle {
        font-size: 20px;
      }

      &_max {
        font-size: 24px;
      }
    }
  }

  &.typography_type_text {
    line-height: 1.5;

    &.typography_size {
      &_min {
        font-size: 12px;
      }

      &_middle {
        font-size: 14px;
      }

      &_max {
        font-size: 16px;
      }
    }
  }
}
