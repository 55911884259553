@import '../../style/guideline.scss';

.export {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 420px;
  height: 100%;

  color: $white;
  background: $blackBlue;

  hr {
    width: 100%;
    margin: 50px 0;

    opacity: 0.5;
  }

  .additional-settings {
    display: flex;
    flex-direction: column;

    margin-bottom: 30px;

    & > :first-child {
      display: flex;
      justify-content: space-between;

      & > div {
        cursor: pointer;
      }
    }

    &> :nth-child(2) {
      margin-top: 40px;
    }
  }

  form {
    overflow: scroll;

    height: 100%;
    padding: 40px;

    .form--group--content {
      .button {
        margin-left: 10px;
      }
    }

    button {
      margin-top: 10px;
    }

    .delete {
      cursor: pointer;
      transition: $transition;

      &:hover {
        opacity: 0.5;
      }
    }

    .extraFiles {
      .form--group--content {
        &:first-child {
          margin-top: 10px;
        }

        &:last-child {
          margin-bottom: 10px;
        }

        & > div {
          display: flex;
          justify-content: space-between;

          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }

  * {
    pointer-events: initial;
  }
}
