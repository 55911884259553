.FormGroup {
  display: flex;
  flex-direction: column;

  &-label {
    margin-bottom: 15px;
    font-size: 16px;
    color: white;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: black;
  }

  &.FormGroup-contentCenter {
    .FormGroup-content {
      align-items: center;

      & > div {
        margin: 0 10px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &.FormGroup-fullWidth {
    width: 100%;

    .FormGroup-content {
      width: 100%;

      > * {
        width: 100%;
      }
    }
  }

  &.FormGroup-labelRight {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;

    .FormGroup-label {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }

  &.FormGroup-labelLeft {
    flex-direction: row;
    align-items: center;

    .FormGroup-label {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  & + .FormGroup {
    margin-top: 30px;
  }
}
