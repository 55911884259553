@import '../../style/guideline.scss';

.form {
  display: flex;
  flex-direction: column;

  &--form-title {
    margin-bottom: 50px;

    font-size: 28px;
  }

  [readonly] {
    background: #c5c5c5;
  }

  &--group {
    display: flex;
    flex-direction: column;

    & + .form--group {
      margin-top: 30px;
    }

    &.checkbox {
      label {
        cursor: pointer;
        input {
          margin-right: 10px;
        }
      }
    }

    &:not(.checkbox) {
      label {
        margin-bottom: 15px;

        font-size: 16px;
      }

      input {
        width: 100%;

        outline: none;

        font-size: 14px;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:not([type='file']) {
          box-sizing: border-box;
          height: 50px;
          margin: 0;
          padding: 0 15px;

          border-radius: $borderRadius;

          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }
      }

      select {
        width: 100%;
        height: 50px;

        outline: none;
        background: $white;

        font-size: 14px;
      }
    }

    &--content {
      display: flex;
      justify-content: space-between;

      &.center {
        align-items: center;

        & > div {
          margin: 0 10px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.form[data-theme='dark'] {
  .FormGroup {
    &-label {
      color: black;
    }
  }

  .form {
    &--group {
      input {
        &:not([type='file']) {
          border: 1px solid #000;
        }
      }
    }
  }
}

.form[data-theme='light'] {
  .form {
    &--group {
      input {
        &:not([type='file']) {
          border: 1px solid $secondColor;
        }
      }
    }
  }
}
