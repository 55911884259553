@import '../../style/guideline.scss';

.slice {
  position: absolute;
  top: 0;
  left: 0;

  overflow: hidden;

  &--row {
    display: flex;

    &--cell {
      box-sizing: border-box;

      cursor: pointer;
      transition: background 500ms;
      pointer-events: initial;

      border: 1px solid green;

      &.selected {
        background: rgba(255, 0, 0, 0.5);
      }
    }
  }
}
