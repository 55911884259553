@import '../../style/guideline.scss';

body {
  .canvas-container {
    position: relative;

    display: grid;
    overflow: scroll;

    width: calc(100vw - 420px);
    height: 100%;

    pointer-events: initial;

    canvas {
      // margin: 0 auto;
      box-sizing: border-box;
    }
  }
}
