@import '../../style/guideline.scss';

.modal[data-modal='preview'] {
  .modal-both-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    & > * + * {
      margin-left: 20px;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 768px;
    padding: 40px 20px;

    border-radius: 10px;
    background: white;
  }

  .modal-content-for-both {
    max-width: 1532px;
  }

  .modal-title {
    margin-bottom: 24px;
  }

  .control-wrapper {
    display: flex;
    align-items: center;
  }

  .image-wrapper {
    display: flex;
    align-items: center;

    margin: 0 40px;
    margin-bottom: 20px;

    &.fullHeight {
      height: 100%;
    }
  }

  img {
    width: 100%;

    border: 1px solid rgb(224, 224, 224);
  }

  button[type='button'] {
    width: 40px;
    min-width: 40px;
    height: 40px;

    cursor: pointer;
    transition: all 0.25s;

    color: $white;
    border: none;
    border-radius: 50%;
    outline: none;
    background: $blue;

    &:hover {
      background: lighten($blue, 10%);
    }
  }

  .testSaveButtons {
    display: flex;

    margin-bottom: 12px;
    .button {
      flex: 1;

      width: auto;

      & + .button {
        margin-left: 12px;
      }
    }
  }
}
