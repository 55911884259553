@import '../../style/guideline.scss';

.loader {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background: $white;

  .svg-image {
    width: 80px;
    height: 80px;

    div,
    svg {
      width: 100%;
      height: 100%;

      stroke: $black;
    }
  }

  .title {
    margin-top: 24px;
  }
}
