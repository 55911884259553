html {
  overflow: hidden;
}
.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
}
.test {
  position: relative;
}
.collapsed-test {
  padding: 20px;
  border-top: 1px solid;
}
.test-container {
  position: relative;
  align-items: center;
  display: flex;
  width: 100%;
  min-height: 600px;
  background: #e7f8ff;
  margin: 20px 0;
  .index {
    position: absolute;
    font-size: 20px;
    top: 10px;
    left: 10px;
  }
  .canvas-container {
    align-items: center;
    justify-content: center;
    overflow: scroll;
  }
}
canvas {
  border: 1px solid darkblue;
}
