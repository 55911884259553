body {
  font-family: arial;

  #app {
    display: flex;

    box-sizing: border-box;
    min-height: 100vh;
  }

  .rebuild-button {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
