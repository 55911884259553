.ExportFormPillars {
  .FormGroup-content {
    .FormGroup-label {
      white-space: nowrap;
    }

    .Input {
      & + .Input {
        margin-top: 20px;
      }
    }
  }
}
