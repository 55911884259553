.FormGroupCollapse {
  &-labelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;

    span {
      &:last-of-type {
        cursor: pointer;
        user-select: none;
      }
    }

    & + div {
      margin-top: 40px;
    }
  }

  &-subItems {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
}
